import React, { useState, useEffect, useContext } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { navigate, Link } from 'gatsby'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useLocation } from '@reach/router'
import { useTextParser } from '@hooks'

import TrackingContext from '@providers/trackingProvider'
import { makeStyles } from '@material-ui/core/styles'

import { Index } from 'elasticlunr'
import queryString from 'query-string'

import Tabs from '@objects/tabs'
import SearchInput from '@objects/searchinput'
import Headline from '@objects/headline'
import Copy from '@objects/copy'

const useStyles = makeStyles((theme) => ({
  resultsRoot: {
    paddingTop: theme.spacing(17),
  },
  results: {
    paddingTop: theme.spacing(8, 4, 0, 4),
  },
  noresults: {
    padding: theme.spacing(17, 4),
  },
  resultList: {
    minHeight: theme.spacing(100),
    padding: theme.spacing(0, 4),
  },
  result: {
    display: 'block',
    marginBottom: theme.spacing(10),
  },
  resultType: {
    ...theme.typography.type,
    marginBottom: theme.spacing(1),
  },
  resultCopy: {
    ...theme.typography.teaser,
  },
  resultHeadline: {
    ...theme.typography.h5,
  },
}))

function SearchResults({ className, searchIndex }) {
  const { parseText } = useTextParser()

  const location = useLocation()
  const classes = useStyles()
  const TrackingCtx = useContext(TrackingContext)
  const intl = useIntl()
  const [index, setIndex] = useState(null)

  const [query, setQuery] = useState('')
  const [resultsAll, setResultsAll] = useState([])
  const [resultsPress, setResultsPress] = useState([])
  const [resultsInform, setResultsInform] = useState([])
  const [resultsMagazine, setResultsMagazine] = useState([])
  const [resultsFaqs, setResultsFaqs] = useState([])
  const [resultsMedia, setResultsMedia] = useState([])

  useEffect(() => {
    if (!index && searchIndex) setIndex(Index.load(searchIndex))
  }, [])

  useEffect(() => {
    setQuery(queryString.parse(location.hash).searchquery)
  }, [location.hash])

  useEffect(() => {
    if (!query.length) return
    search(query)
  }, [query])

  useEffect(() => {
    setResultsPress(
      resultsAll.filter((result) => result.type === 'pressarticle')
    )
    setResultsInform(
      resultsAll.filter((result) => result.type === 'topicarticle')
    )
    setResultsMagazine(
      resultsAll.filter((result) => result.type === 'magazinearticle')
    )
    setResultsFaqs(resultsAll.filter((result) => result.type === 'Faq'))
    setResultsMedia(resultsAll.filter((result) => result.type === 'Media'))
  }, [resultsAll])

  function search(searchquery) {
    setResultsAll(
      index
        .search(searchquery, {})
        .map(({ ref }) => index.documentStore.getDoc(ref))
        .filter((result) => result.locale === intl.locale)
    )

    navigate(`${location.pathname}#searchquery=${searchquery}`, {
      replace: true,
    })
  }

  function TabHeadClickEvent(name) {
    TrackingCtx.PushMessage('custom.search-tabchanged', {
      message: name,
    })
  }

  function results(results) {
    const renderedResults = results.map((result, i) => {
      return (
        <Link
          data-track-content
          data-tracking-id={result.path}
          key={`${result.path}${i}`}
          className={classes.result}
          to={result.path}
          aria-label={`Link - ${result.title}`}
        >
          {renderResultInner(result)}
        </Link>
      )
    })
    return (
      <>
        <Headline level={1}>
          <FormattedMessage
            id="search.headline"
            values={{ query: <strong>{query}</strong> }}
          />
        </Headline>
        <div
          role="list"
          aria-label={intl.formatMessage({
            id: 'searchresults.results.all',
          })}
        >
          {renderedResults}
        </div>
      </>
    )
  }

  function renderResultInner(result) {
    let parsedText
    if (!!result.copy) {
      if (result.copy[0] !== '{') {
        parsedText = result.copy
      } else {
        const JSONParsedCopy = JSON.parse(result.copy)
        parsedText = JSONParsedCopy.content[0].content[0].value
      }
    }
    return (
      <>
        <div className={classes.resultType}>
          <FormattedMessage id={'search.result.' + result.type} />
        </div>
        <Headline level={2} className={classes.resultHeadline}>
          {result.title}
        </Headline>
        <Copy className={classes.resultCopy} truncate={45}>
          {parsedText}
        </Copy>
      </>
    )
  }

  return (
    <div className={clsx(className, classes.resultsRoot)}>
      <SearchInput
        resultsAll={resultsAll}
        searchfunc={search}
        searchquery={query}
        noautosuggest
      />
      {resultsAll.length > 0 ? (
        <div className={classes.resultList}>
          <Tabs
            className={classes.result}
            tabHeads={[
              {
                label: `${intl.formatMessage({ id: 'search.result.all' })} (${
                  resultsAll.length
                })`,
                onClick: () => {
                  TabHeadClickEvent('resultsAll')
                },
              },
              {
                label: `${intl.formatMessage({
                  id: 'search.result.topicarticle',
                })} (${resultsInform.length})`,
                onClick: () => {
                  TabHeadClickEvent('resultsTopic')
                },
              },
              {
                label: `${intl.formatMessage({
                  id: 'search.result.magazinearticle',
                })} (${resultsMagazine.length})`,
                onClick: () => {
                  TabHeadClickEvent('resultsMagazine')
                },
              },
              {
                label: `${intl.formatMessage({
                  id: 'search.result.pressarticle',
                })} (${resultsPress.length})`,
                onClick: () => {
                  TabHeadClickEvent('resultsPress')
                },
              },
              {
                label: `${intl.formatMessage({ id: 'search.result.Faq' })} (${
                  resultsFaqs.length
                })`,
                onClick: () => {
                  TabHeadClickEvent('resultsFaq')
                },
              },
              {
                label: `${intl.formatMessage({ id: 'search.result.Media' })} (${
                  resultsMedia.length
                })`,
                onClick: () => {
                  TabHeadClickEvent('resultsMedia')
                },
              },
            ]}
          >
            <div>{results(resultsAll)}</div>
            <div>{results(resultsInform)}</div>
            <div>{results(resultsMagazine)}</div>
            <div>{results(resultsPress)}</div>
            <div>{results(resultsFaqs)}</div>
            <div>{results(resultsMedia)}</div>
          </Tabs>
        </div>
      ) : (
        <div className={classes.noresults}>
          <FormattedMessage
            id="search.noresult"
            values={{
              searchterm: <strong>{query}</strong>,
              break: <br />,
            }}
          />
        </div>
      )}
    </div>
  )
}

SearchResults.propTypes = {
  className: PropTypes.string,
  searchIndex: PropTypes.object.isRequired,
}

export default SearchResults
